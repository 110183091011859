import firebase from "firebase/compat/app";
import { init } from "next-firebase-auth";

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000;

export const getCookieKeys = (): string[] => {
  if (process.env.AUTH_COOKIE_SECRET) {
    return [process.env.AUTH_COOKIE_SECRET];
  }

  return [];
};

const initAuth = () => {
  const clientConfig = {
    apiKey: "AIzaSyB6AU_jO__2qHnntQryCs91Az5nB9pvPUU",
    authDomain: "publit-co.firebaseapp.com",
    databaseURL: "https://publit-co.firebaseio.com",
    projectId: "publit-co",
    storageBucket: "publit-assets",
    messagingSenderId: "245215828829",
    appId: "1:245215828829:web:6c55b7b097b6b3567966b4",
    measurementId: "G-4H12JRKH89",
  };

  init({
    authPageURL: "/login",
    appPageURL: "/dashboard/",
    loginAPIEndpoint: "/api/auth/login", // required
    logoutAPIEndpoint: "/api/auth/logout", // required
    // firebaseAuthEmulatorHost: "localhost:9099",
    // Required in most cases.
    firebaseAdminInitConfig: {
      credential: {
        projectId: "publit-co",
        clientEmail:
          "firebase-adminsdk-8edcp@publit-co.iam.gserviceaccount.com",
        // The private key must not be accesssible on the client side.
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY)
          : undefined,
      },
      databaseURL: "https://publit-co.firebaseio.com",
    },
    firebaseClientInitConfig: clientConfig,
    cookies: {
      name: "publit_dashboard", // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: getCookieKeys(),
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS, // twelve days
      overwrite: true,
      path: "/",
      // sameSite: process.env.NODE_ENV === "production" ? "strict" : "lax",
      sameSite: "lax",
      secure: process.env.NODE_ENV === "production", // set this to false in local (non-HTTPS) development
      signed: true,
    },
  });

  firebase.initializeApp(clientConfig);
};

export default initAuth;
